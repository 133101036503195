import { Box, Grid } from "@chakra-ui/react";
import React from "react";
import VerticalTextAndBox from "../../layout/VerticalTextAndBox";
import { StaticImage } from "gatsby-plugin-image";

interface InsightCategoryProps {}

const InsightCategory: React.FC<InsightCategoryProps> = () => {
  return (
    <Grid gridTemplateColumns={{ base: "1fr", lg: "1fr 1fr 1fr" }} gap={8}>
      <VerticalTextAndBox
        subtitle="Wasaline's Power BI for Data-driven optimization"
        body="Wasaline turned to Indevit to address their challenges, which included difficulties accessing data from their systems and generating relevant reports to support their operation."
        image={
          <Box height="13.5rem">
            <StaticImage
              style={{ width: "100%", height: "100%" }}
              alt="JNS"
              src="../../../images/Wasaline-cover-photon.jpg"
              placeholder="none"
            />
          </Box>
        }
        link="/wasaline"
        buttonColorScheme="primary"
      />

      <VerticalTextAndBox
        subtitle="The Baltic Sea heritage exhibition"
        body="John Nurminen Säätiö, a protector of the Baltic Sea, wanted to highlight their important work that promotes maritime culture and environment into an informative and easy-guided virtual experience. "
        image={
          <Box height="13.5rem">
            <StaticImage
              style={{ width: "100%", height: "100%" }}
              alt="JNS"
              src="../../../images/cover-image-JNS.jpg"
              placeholder="none"
            />
          </Box>
        }
        link="/jns"
        buttonColorScheme="secondary"
      />

      <VerticalTextAndBox
        subtitle="Event marketing for Svenska Folkskolans Vänner"
        body="Event marketing for Mera - Flera - Starkare! An event that focused on future-proofing voluntary engagement."
        image={
          <Box height="13.5rem">
            <StaticImage
              style={{ width: "100%", height: "100%" }}
              alt="Business intelligence"
              src="../../../images/insight-article-02.jpg"
              placeholder="none"
            />
          </Box>
        }
        link="/eventmarket"
        buttonColorScheme="secondary"
      />
      <VerticalTextAndBox
        subtitle="Transform your concepts into reality"
        body=" Want to see your digital idea come to life before the development process even starts?
        Then a prototype is the perfect solution for you!"
        image={
          <Box height="13.5rem">
            <StaticImage
              style={{ width: "100%", height: "100%" }}
              alt="Business intelligence"
              src="../../../images/Cover-prototyping.jpg"
              placeholder="none"
            />
          </Box>
        }
        link="/prototype"
        buttonColorScheme="secondary"
      />
      <VerticalTextAndBox
        subtitle="Fast integration for Entia"
        body="Founded in 2007, Entia, led by Ronnie Ollila, provides high-quality IT services. 
        Facing challenges with manual invoicing, 
        they teamed up with Indevit to streamline operations and integrate their systems."
        image={
          <Box height="13.5rem">
            <StaticImage
              style={{ width: "100%", height: "100%" }}
              alt="Business intelligence"
              src="../../../images/indevit-entia-3.jpg"
              placeholder="none"
            />
          </Box>
        }
        link="/entia"
        buttonColorScheme="primary"
      />
    </Grid>
  );
};

export default InsightCategory;
